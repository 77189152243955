import API from "~@app/const/API";

const authHost = ""

export default {
  LOGOUT: `${authHost}/auth/logout`,
  LOGIN: `${authHost}/login`,
  LOGIN_ERROR: `${authHost}/login?error`,
  LOGIN_ACCESS_TOKEN: `${API.BASE.API_COMMAND_PATH}auth/token`,
  REDIRECT_LOGIN_LOCATION: "redirectLoginLocation",
  REVOKE_ACCESS_TOKEN: `${API.BASE.API_COMMAND_PATH}pub/api/oauth2/access-token/revoke`,
  ME: `${API.BASE.API_COMMAND_PATH}user/me`,
}