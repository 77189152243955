import BaseEvent from "~@core/event/BaseEvent";

export interface HttpErrorEventDetail {
  response: Response | unknown[];
}

export default class HttpErrorEvent extends BaseEvent<HttpErrorEventDetail> {
  constructor(details: HttpErrorEventDetail) {
    super("http", details);
  }
}