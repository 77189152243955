import {useContext} from 'react';
import {AppContext} from "~@app/component/app/AppProvider";
import BaseError from "~@core/error/BaseError";

export default function useApp() {
  const ret = useContext(AppContext);
  if (!ret) {
    throw new BaseError('Not in App Context');
  }
  return ret;
}