import _ from 'lodash';
import {AnyArray, AnyOf} from '~@core/type/Common';

export default {
  invoke<T>(value: unknown, ...args: unknown[]): AnyOf<T> {
    if (_.isFunction(value)) {
      return value(...args);
    }
    return value as AnyOf<T>;
  },
  async invokeAsync<C>(value: C, ...args: unknown[]): Promise<C> {
    if (_.isFunction(value)) {
      return value(...args);
    }
    return value;
  },
  invokeWithDefault<T>(value: unknown, args: AnyArray = [], defaultValue: T | null = null): AnyOf<T> {
    const ret = this.invoke(value, ...(args || []));
    return (ret === undefined ? defaultValue : ret) as AnyOf<T>;
  },
  call<T>(fnc: unknown, ...args: unknown[]): AnyOf<T> {
    if (_.isFunction(fnc)) {
      return fnc(...args);
    }
    return undefined;
  },
  callWithDefault(value: unknown, args: AnyArray = [], defaultValue: unknown = null): unknown {
    const ret = this.call(value, ...(args || []));
    return ret === undefined ? defaultValue : ret;
  },
  mapInvoke(elements: unknown, ...args: unknown[]): Record<string, unknown> | unknown[] {
    if (_.isArray(elements)) {
      return _.map(elements, (element) => this.invoke(element, ...args));
    }
    return _.mapValues(elements as Record<string, unknown>, (element) => this.invoke(element, ...args));
  },
  objectMapInvoke(elements: unknown, ...args: unknown[]): Record<string, unknown> {
    return _.mapValues(elements as Record<string, unknown>, (element) => this.invoke(element, ...args));
  },
  objectMapInvokeRecursive(elements: unknown, ...args: unknown[]): unknown {
    if (_.isArray(elements)) {
      return _.map(elements as unknown[], (element) => this.objectMapInvokeRecursive(element, ...args))
    }
    if (_.isObject(elements)) {
      return _.mapValues(elements as Record<string, unknown>, (element) => this.objectMapInvokeRecursive(element, ...args));
    }
    if (_.isFunction(elements)) {
      this.invoke(elements, ...args)
    }
    return elements;
  },
  extractWithPrefix(data: unknown, prefix: string): unknown {
    const ret = {};
    _.each(data as Record<string, unknown>, (value, key: string) => {
      if (prefix && _.startsWith(key, prefix)) {
        ret[key.substring(prefix.length)] = value;
      } else {
        ret[key] = value;
      }
    });
    return ret;
  },
  diff(a: Record<string, unknown> | null | undefined, b: Record<string, unknown> | null | undefined): string[] {
    return _.reduce(a, (result: string[], value, key) => (_.isEqual(value, _.get(b, key)) ? result : result.concat(key)), []);
  },
  deepFreeze(object) {
    if (!_.isObject(object)) {
      return object;
    }
    // Retrieve the property names defined on object
    const propNames = Reflect.ownKeys(object);

    // Freeze properties before freezing self
    // eslint-disable-next-line no-restricted-syntax
    for (const name of propNames) {
      const value = object[name];

      if ((value && typeof value === 'object') || typeof value === 'function') {
        this.deepFreeze(value);
      }
    }

    return Object.freeze(object);
  },
  deepCloneFreeze(object) {
    if (object === null || object === undefined) {
      return object;
    }
    return this.deepFreeze(_.cloneDeep(object));
  },
  updateElement<T>(collection: T[], filter: unknown, element: T | (() => T)): AnyOf<T> {
    if (!collection) {
      return undefined;
    }
    // @ts-ignore lodash safe
    const idx = _.findIndex(collection, filter);
    if (idx >= 0) {
      const el = this.invoke(element) as AnyOf<T>;
      if (el) {
        // eslint-disable-next-line no-param-reassign
        collection[idx] = el;
        return collection[idx];
      }
    }
    return undefined;
  },
  upsertElement<T>(collection: T[], filter, element: T | (() => T)): AnyOf<T> {
    const idx = _.findIndex(collection, filter);
    const el = this.invoke(element) as AnyOf<T>;
    if (el) {
      if (idx >= 0) {
        // eslint-disable-next-line no-param-reassign
        collection[idx] = el;
        return collection[idx];
      }

      collection.push(el);
      return el
    }
    return undefined;
  },
  deleteElement<T>(collection: T[], filter): AnyOf<T> {
    const idx = _.findIndex(collection, filter);
    if (idx >= 0) {
      const old = collection[idx];
      collection.splice(idx, 1);
      return old;
    }
    return undefined;
  },
  isBlankObject(value) {
    return _.isEmpty(_.filter(value, e => !(e === null || e === undefined)))
  },
  castMultiple<T>(value?: T | T[] | undefined | null, multiple = false): T | T[] | undefined | null {
    if (value == null || value == undefined) {
      return value;
    }
    const isArr = _.isArray(value)
    if (multiple && !isArr) {
      return [value];
    }
    if (!multiple && isArr) {
      return _.first(value);
    }
    return value;
  },
  castToScalar(value, callback) {
    return _.isObject(value) ? callback(value) : value;
  }
};