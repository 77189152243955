class StorageUtil {
  private readonly prefix: any;

  constructor(prefix: unknown) {
    this.prefix = prefix;
  }

  key(key) {
    return `${this.prefix}_${key}`;
  }

  get(key) {
    return window.localStorage.getItem(this.key(key));
  }

  set(key, value) {
    return window.localStorage.setItem(this.key(key), value);
  }

  remove(key) {
    return window.localStorage.removeItem(this.key(key));
  }

  getObject(key) {
    const val = this.get(key);
    try {
      return val ? JSON.parse(val) : null;
    } catch (e) {
      return null;
    }
  }

  setObject(key, value) {
    return this.set(key, JSON.stringify(value));
  }
}

export default new StorageUtil('web');