import {BreadcrumbItem} from '~@core/component/Type';
import {trans} from "~@core/util/StringUtil";

export const ROUTES = {
  HOME: '/blog',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  PROFILE: '/profile',
  USER: '/user',
  BLOG: '/blog',
  BLOG_CATEGORY: '/blog/category',
  FAMILY: '/family',
  LOGOUT: '/logout',
  CHANGE_PASSWORD: '/change-password',
};
export const BREADCRUMBS_MAP: Record<string, string | BreadcrumbItem> = {
  '/': 'Trang chủ',
  '/admin': trans('admin'),
  '/admin/blog': trans('blog:blog'),
  '/admin/blog/gallery': trans('blog:gallery'),
  '/admin/blog/create': trans('blog:create'),
  '/admin/blog/update': trans('blog:update'),
  '/admin/blog/category': trans('blog:blogCategory'),
  '/admin/blog/category/create': trans('blog:createBlogCategory'),
  '/admin/blog/category/update': trans('blog:updateBlogCategory'),
  '/admin/family': trans('family:family'),
  '/admin/family/create': trans('family:create'),
  '/admin/family/update': trans('family:update'),
  '/admin/user': trans('user:user'),
  '/admin/user/create': trans('user:create'),
  '/admin/user/update': trans('user:update'),
};

export const MIME_TYPE = {
  IMAGE: 'image/*',
  PDF: 'application/pdf',
  WORD: 'application/msword',
  WORD_X: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL: 'application/vnd.ms-excel',
  EXCEL_X: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: '.csv',
  ZIP: 'application/zip,application/x-zip-compressed,multipart/x-zip',
  ZIP_7z: 'application/x-7z-compressed',
  RAR: 'application/x-rar-compressed',
  OUTLOOK_EMAIL: '.msg'
}