import {useContext} from 'react';
import {AuthContext} from "~@app/component/auth/AuthContextProvider";
import BaseError from "~@core/error/BaseError";

export default function useAuth() {
  const ret = useContext(AuthContext);
  if (!ret) {
    throw new BaseError('Not in Auth Context');
  }
  return ret;
}