import _ from 'lodash'
import {Url} from 'next/dist/shared/lib/router/router'
import {useRouter} from "next/router";
import React, {createContext, FC, PropsWithChildren, useEffect, useMemo, useState,} from 'react';
import NotFoundPage from "~@app/component/page/NotFoundPage"
import {AnyObject, AnyString} from '~@core/type/Common'

import urlUtil from '~@core/util/UrlUtil'
import UrlUtil from '~@core/util/UrlUtil'

export interface AppContextType {
  getParams()

  getParam(path: string)

  setPageRoot(pageRoot?: string)

  redirect(path, params?: AnyObject)

  push(url: Url, as?: Url, options?)

  notFound()

  navigate(path, params?: string | number | AnyObject, replace?: boolean): Promise<boolean>

  newTab(path, params)

  mediaUrl(url: string, domain?: AnyString): string

  url(path, params?: AnyObject)

  pageUrl(path, params?: AnyObject)

  homeUrl(params?: AnyObject)

  navigateToPage(url: string, params?: AnyObject): void
}

export const AppContext = createContext<AppContextType | null>(null);

export interface AppProviderProps extends PropsWithChildren {
  domain?: string
}

const AppProvider: FC<AppProviderProps> = ({domain = 'giapha', children}) => {
  const router = useRouter()
  const familyCode = router.query?.familyCode as string | undefined;
  const [pageRoot, setPageRoot] = useState<string>()
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    setPageRoot(familyCode)
  }, [familyCode]);

  const contextValue = useMemo<AppContextType>(() => {
    return {
      getParams() {
        return router.query;
      },
      getParam(path) {
        return _.get(router.query, path);
      },
      setPageRoot(pageRoot?: string) {
        setPageRoot(pageRoot);
      },
      redirect(path, params: AnyObject = null) {
        window.location.href = urlUtil.toUrlString(path, params)
      },
      push(url: Url, as?: Url, options?) {
        return router.push(url, as, options)
      },
      async navigate(path, params: string | number | AnyObject = null, replace = false) {
        const url = urlUtil.toPathString(path, params)
        if (replace) {
          return router.replace(url, url)
        }
        return router.push(url, url)
      },
      newTab(path, params) {
        let url = urlUtil.toPathString(path, params)
        const pathName = window.location.pathname
        if (_.startsWith(url, '#')) {
          url = urlUtil.concat(pathName, url)
        }
        window.open(urlUtil.toUrlString(url), '_blank')
      },
      mediaUrl(url: string, domain: AnyString = null): string {
        return urlUtil.toUrlString(url, null, domain || domain)
      },
      url(path, params: AnyObject = null) {
        return urlUtil.toUrlString(path, params)
      },
      pageUrl(path, params: AnyObject = null) {
        return pageRoot
          ? this.url(UrlUtil.concat(pageRoot, path), params)
          : this.url(path, params);
      },
      homeUrl(params: AnyObject = null) {
        return this.url(pageRoot || '/', params)
      },
      notFound() {
        setNotFound(true)
      },
      navigateToPage(url: string, params: AnyObject = null) {
        if (url && (url.toLowerCase().startsWith("http://") || url.toLowerCase().startsWith("https://"))) {
          return this.navigate(url, params)
        }
        return this.navigate(this.pageUrl(url, params))
      }
    }
  }, [router, domain, pageRoot]);

  return (
    <AppContext.Provider value={contextValue}>
      {notFound ? <NotFoundPage/> : children}
    </AppContext.Provider>
  );
};

export default AppProvider;