import {AnyObjectArray, AnyOf} from "~@core/type/Common";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';

let NEXT_ID = 0;
export default {
  next() {
    NEXT_ID += 1;
    return NEXT_ID;
  },
  uuid4() {
    return uuidv4();
  },
  fillLocalUUID(collection: AnyObjectArray | unknown[], idField = 'id') {
    _.each(collection, e => {
      if (e) {
        // eslint-disable-next-line no-underscore-dangle
        e[`_${idField}`] = e[`_${idField}`] || e[idField] || uuidv4();
      }
    })
  },
  isEqualById<T>(a: AnyOf<T>, b: AnyOf<T>, idField = 'id') {
    const aId = _.get(a, `_${idField}`);
    const bId = _.get(b, `_${idField}`);
    return aId && bId && aId === bId;
  }
}
