import Link from "~@core/component/link/Link"
import StorageUtil from "~@core/util/StorageUtil"

export default function NotFoundPage() {
  return <div>Page Not Found, click here to back <Link href='#' onClick={(event) => {
    event.stopPropagation();
    event.preventDefault();

    StorageUtil.remove('lastFamilyCode');
    window.location.href = '/'
    return false;
  }}>home page</Link></div>
}