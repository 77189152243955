import i18n from "~@app/component/I18n";

export const asString = (e: unknown | null | undefined, def = "") => e === null || e === undefined ? def : e.toString()
export const trans = (key: string, defaultValue?: string, data ?: object) => {
  return defaultValue === undefined ? i18n.t(key, data || {}) : i18n.t(key, defaultValue, data || {})
}

export const stripAccents = (value: string) => {
  return value.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}
export default {
  asString,
  trans,
  stripAccents
}