import _ from 'lodash';
import HttpError from '~@core/error/HttpError';
import HttpErrorEvent from '~@core/event/HttpErrorEvent';
import {SuccessResponse} from '~@core/type/ApiType';
import CommonUtil from '~@core/util/CommonUtil';
import {asString} from '~@core/util/StringUtil';
import UrlUtil from '~@core/util/UrlUtil';

interface HttpOptions {
  headers?: Record<string, unknown>;
}

export default class Http {
  private readonly customHeaders: Record<string, string>;


  constructor(customHeaders?: Record<string, string>) {
    this.customHeaders = customHeaders || {};
  }

  async parseSuccessApiResponse<T>(res: Response | unknown[]): Promise<SuccessResponse<T>> {
    if (_.isArray(res)) {
      return res as unknown as SuccessResponse<T>;
    }
    if (res.status < 200 || res.status >= 300) {
      window.dispatchEvent(new HttpErrorEvent({response: res}));
    }
    let body;
    try {
      body = await res.json();
    } catch (ex) {
      throw new HttpError(res, undefined, ex);
    }
    if (!body) {
      return [null as unknown as T, body, res];
    }
    if (body?.status === 'ok') {
      return [body?.data, body, res];
    }
    throw new HttpError(res, body);
    // return [resp, resp, res];
  }

  async parseErrorApiResponse<T>(error: unknown): Promise<T[]> {
    throw new HttpError(undefined, undefined, error);
  }

  addHeaders(headers: Record<string, string | (() => unknown)>): void {
    _.assign(this.customHeaders, headers);
  }

  removeHeader(headerName: string): void {
    if (this.customHeaders[headerName] !== undefined) {
      delete this.customHeaders[headerName];
    }
  }

  getApiHeaders(options?: HttpOptions): Record<string, string> {
    const headers = CommonUtil.objectMapInvoke({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...this.customHeaders,
      ...options?.headers
    });
    const ret = _.mapValues(headers, asString);
    return _.pickBy(ret);
  }

  getFileHeaders(): Record<string, string> {
    const headers = CommonUtil.objectMapInvoke({
      Accept: 'application/json',
      ...this.customHeaders
    });
    return _.mapValues(headers, asString);
  }

  callGet<T>(url: string, data?: Record<string, unknown> | unknown | null, options?: HttpOptions) {
    const endpoint = UrlUtil.toUrlString(url, data);
    return fetch(endpoint, {
      headers: this.getApiHeaders(options)
    }).catch(this.parseErrorApiResponse<T>)
      .then(this.parseSuccessApiResponse<T>);
  }

  callPost<T>(url: string, data?: Record<string, unknown> | unknown, options?: HttpOptions) {
    const endpoint = UrlUtil.toUrlString(url);
    return fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: this.getApiHeaders(options),
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    }).catch(this.parseErrorApiResponse<T>)
      .then(this.parseSuccessApiResponse<T>);
  }

  callPut<T>(url: string, data: Record<string, unknown> | unknown, options?: HttpOptions) {
    const endpoint = UrlUtil.toUrlString(url);
    return fetch(endpoint, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: this.getApiHeaders(options),
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    }).catch(this.parseErrorApiResponse<T>)
      .then(this.parseSuccessApiResponse<T>);
  }

  callPatch<T>(url: string, data: Record<string, unknown> | unknown, options?: HttpOptions) {
    const endpoint = UrlUtil.toUrlString(url);
    return fetch(endpoint, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: this.getApiHeaders(options),
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    }).catch(this.parseErrorApiResponse<T>)
      .then(this.parseSuccessApiResponse<T>);
  }

  callDelete<T>(url: string, data: Record<string, unknown> | unknown, options?: HttpOptions) {
    const endpoint = UrlUtil.toUrlString(url, data);
    return fetch(endpoint, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: this.getApiHeaders(options),
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    }).catch(this.parseErrorApiResponse<T>)
      .then(this.parseSuccessApiResponse<T>);
  }

  callUploadFile<T>(url: string, file: File, data: Record<string, unknown> | never, method = 'POST') {
    const endpoint = UrlUtil.toUrlString(url);
    const formData = new FormData();
    formData.append('file', file, file.name);
    _.each(data as Record<string, unknown>, (value, key) => {
      formData.append(key, _.isObject(value) ? JSON.stringify(value) : value as string | Blob);
    });
    return fetch(endpoint, {
      method,
      headers: {
        ...this.getFileHeaders()
      },
      body: formData
    }).then(this.parseSuccessApiResponse<T>)
      .catch(this.parseErrorApiResponse<T>);
  }

  callUploadBlobFile<T>(url: string, blob: Blob, fileName: string, data: Record<string, unknown> | never, method = 'POST') {
    const endpoint = UrlUtil.toUrlString(url);
    const formData = new FormData();
    formData.append('file', blob, fileName);
    _.each(data as Record<string, unknown>, (value, key) => {
      formData.append(key, _.isObject(value) ? JSON.stringify(value) : value as string | Blob);
    });
    return fetch(endpoint, {
      method,
      headers: {
        ...this.getFileHeaders()
      },
      body: formData
    }).then(this.parseSuccessApiResponse<T>)
      .catch(this.parseErrorApiResponse<T>);
  }

  callUploadFiles<T>(url: string, files: File[], data: Record<string, unknown> | unknown, method = 'POST') {
    const endpoint = UrlUtil.toUrlString(url);
    const formData = new FormData();
    _.each(files, (file) => {
      formData.append('files', file, file.name);
    });
    _.each(data as Record<string, unknown>, (value, key) => {
      formData.append(key, _.isObject(value) ? JSON.stringify(value) : value as string | Blob);
    });
    return fetch(endpoint, {
      method,
      headers: {
        ...this.getFileHeaders()
      },
      body: formData
    }).then(this.parseSuccessApiResponse<T>)
      .catch(this.parseErrorApiResponse<T>);
  }
}