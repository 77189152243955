export default class BaseEvent<T> extends Event {
  readonly details: T;

  constructor(type: string, details: T & EventInit) {
    super(type, {
      bubbles: details?.bubbles || false,
      cancelable: details?.cancelable || false,
      composed: details?.composed || false
    });
    this.details = details;
  }
}