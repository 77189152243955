import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import _ from 'lodash';
import commonEN from 'locales/en/common.json';
import commonVI from 'locales/vi/common.json';

import userEN from 'locales/en/user.json';
import userVI from 'locales/vi/user.json';

import blogEN from 'locales/en/blog.json';
import blogVI from 'locales/vi/blog.json';

import familyEN from 'locales/en/family.json';
import familyVI from 'locales/vi/family.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'vi',
    lowerCaseLng: true,
    debug: false,
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: commonEN,
        user: userEN,
        blog: blogEN,
        family: familyEN
      },
      vi: {
        common: commonVI,
        user: userVI,
        blog: blogVI,
        family: familyVI
      },
    },
    parseMissingKeyHandler(key, defaultValue) {
      return defaultValue !== undefined
        ? defaultValue
        : _.chain(key).words().map(_.capitalize).join(' ').value();
    },
  });

export default i18n;